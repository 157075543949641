/*! ---------------------------------------------------
    LESS Elements 0.8

    Created by:
    - Dmitry Fadeyev -> http://lesselements.com/

  ---------------------------------------------------
    A set of useful LESS mixins.
    See CREDITS file for full author list.

    More info at: http://lesselements.com
-----------------------------------------------------*/

#gradient {
  .vertical(@color: #F5F5F5, @start: #EEE, @stop: #FFF, @posStart: 0, @posEnd: 1) {
    background: @color;
    background: -webkit-gradient(linear,
                   left bottom,
                   left top,
                   color-stop(@posStart, @start),
                   color-stop(@posEnd, @stop));
    background: -moz-linear-gradient(center bottom,
                     @start @posStart*100%,
                     @stop @posEnd*100%);
    background: -ms-linear-gradient(bottom, @start @posStart*100%,@stop @posEnd*100%);
    filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='@{stop}', endColorstr='@{start}',GradientType=0)";
  }
  .horizontal(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
    background: @color;
    background-image: linear-gradient(right , @stop 0%, @start 100%);
    background-image: -o-linear-gradient(right , @stop 0%, @start 100%);
    background-image: -moz-linear-gradient(right , @stop 0%, @start 100%);
    background-image: -webkit-linear-gradient(right , @stop 0%, @start 100%);
    background-image: -ms-linear-gradient(right , @stop 0%, @start 100%);
    background-image: -webkit-gradient(
      linear,
      right top,
      left top,
      color-stop(0, @stop),
      color-stop(0.5, @start)
    );
    background: -ms-linear-gradient(left, @start 0%,@stop 100%);
    filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='@{stop}', endColorstr='@{start}',GradientType=1)";
  }
  .bw(@color: #F5F5F5, @start: 0, @stop: 255) {
    background: @color;
    background: -webkit-gradient(linear,
                   left bottom,
                   left top,
                   color-stop(0, rgb(@start,@start,@start)),
                   color-stop(1, rgb(@stop,@stop,@stop)));
    background: -ms-linear-gradient(bottom,
                    rgb(@start,@start,@start) 0%,
                    rgb(@start,@start,@start) 100%);
    background: -moz-linear-gradient(center bottom,
                     rgb(@start,@start,@start) 0%,
                     rgb(@stop,@stop,@stop) 100%);
  }
  .radial(@color: #F5F5F5, @start: #000, @stop: #FFF, @shape: circle farthest-corner, @position: center){
    background: @color;
    background-image: radial-gradient(@shape at @position, @start 0%, @stop 100%);
    background-image: -moz-radial-gradient(@position, @shape, @start 0%, @stop 100%);
    background-image: -ms-radial-gradient(@position, @shape, @start 0%, @stop 100%);
    background-image: -o-radial-gradient(@position, @shape, @start 0%, @stop 100%);
    background-image: -webkit-radial-gradient(@position, @shape, @start 0%, @stop 100%);
  }
  .none(){
    background:none;
    filter:none;
  }

}

#border {
  .bordered(@top-color: #EEE, @right-color: #EEE, @bottom-color: #EEE, @left-color: #EEE) {
    border-top: solid 1px @top-color;
    border-left: solid 1px @left-color;
    border-right: solid 1px @right-color;
    border-bottom: solid 1px @bottom-color;
  }
  .rounded(@radius: 2px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
    -moz-background-clip: padding; -webkit-background-clip: padding-box; background-clip: padding-box;
  }

  .radius(@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
    -webkit-border-top-right-radius: @topright;
    -webkit-border-bottom-right-radius: @bottomright;
    -webkit-border-bottom-left-radius: @bottomleft;
    -webkit-border-top-left-radius: @topleft;
    -moz-border-radius-topright: @topright;
    -moz-border-radius-bottomright: @bottomright;
    -moz-border-radius-bottomleft: @bottomleft;
    -moz-border-radius-topleft: @topleft;
    border-top-right-radius: @topright;
    border-bottom-right-radius: @bottomright;
    border-bottom-left-radius: @bottomleft;
    border-top-left-radius: @topleft;
    -moz-background-clip: padding; -webkit-background-clip: padding-box; background-clip: padding-box;
  }
}

#shadow {
  .drop(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.1, @color: #000) {
    @fade: fadeout(@color, 100 - ( @alpha*100 ));
    -webkit-box-shadow: @x-axis @y-axis @blur @fade;
       -moz-box-shadow: @x-axis @y-axis @blur @fade;
            box-shadow: @x-axis @y-axis @blur @fade;
  }
  .inner(@horizontal:0, @vertical:1px, @blur:2px, @alpha: 0.4, @color: #000) {
    @fade: fadeout(@color, 100 - ( @alpha*100 ));
    -webkit-box-shadow: inset @horizontal @vertical @blur @fade;
       -moz-box-shadow: inset @horizontal @vertical @blur @fade;
            box-shadow: inset @horizontal @vertical @blur @fade;
  }
  .both(@horizontalOut:0, @verticalOut:1px, @blurOut:2px, @alphaOut: 0.4, @colorOut: #000,@horizontalIn:0, @verticalIn:1px, @blurIn:2px, @alphaIn: 0.4, @colorIn: #000) {
    @fadeOut: fadeout(@colorOut, 100 - (@alphaOut*100));
    @fadeIn: fadeout(@colorIn, 100 - (@alphaIn*100));
    -webkit-box-shadow: @horizontalOut @verticalOut @blurOut @fadeOut, inset @horizontalIn @verticalIn @blurIn @fadeIn;
       -moz-box-shadow: @horizontalOut @verticalOut @blurOut @fadeOut, inset @horizontalIn @verticalIn @blurIn @fadeIn;
            box-shadow: @horizontalOut @verticalOut @blurOut @fadeOut, inset @horizontalIn @verticalIn @blurIn @fadeIn;
  }
  .box(@arguments) {
    -webkit-box-shadow: @arguments;
    -moz-box-shadow: @arguments;
    box-shadow: @arguments;
  }
  .text(@arguments) {
    -webkit-text-shadow: @arguments;
    -moz-text-shadow: @arguments;
    text-shadow: @arguments;
    -ms-text-shadow: @arguments;
    -o-text-shadow: @arguments;
    -khtml-text-shadow: @arguments;
 }
 .none() {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
 }
}

#transform {
  .rotation(@deg:5deg){
    -webkit-transform: rotate(@deg);
    -moz-transform: rotate(@deg);
    -ms-transform: rotate(@deg);
    -o-transform: rotate(@deg);
    transform: rotate(@deg);
  }
  .scaleY(@ratio:1.5){                                                                                                                                       
    -webkit-transform:scaleY(@ratio);                                                                                                                        
       -moz-transform:scaleY(@ratio);                                                                                                                           
        -ms-transform:scaleY(@ratio);                                                                                                                            
         -o-transform:scaleY(@ratio);                                                                                                                             
            transform:scaleY(@ratio);                                                                                                                                
  }                                                                                                                                                          
  .scaleX(@ratio:1.5){                                                                                                                                       
    -webkit-transform:scaleX(@ratio);                                                                                                                        
    -moz-transform:scaleX(@ratio);                                                                                                                           
    -ms-transform:scaleX(@ratio);                                                                                                                            
    -o-transform:scaleX(@ratio);
    transform:scaleX(@ratio);
  } 
  .scale(@ratio:1.5){                                                                                                                                        
    -webkit-transform:scale(@ratio);                                                                                                                         
    -moz-transform:scale(@ratio);                                                                                                                            
    -ms-transform:scale(@ratio);                                                                                                                             
    -o-transform:scale(@ratio);                                                                                                                              
    transform:scale(@ratio);                                                                                                                                 
  }                                                                                                                                                          

  .translate(@x:0, @y:0) {
    -moz-transform: translate(@x, @y);
    -webkit-transform: translate(@x, @y);
    -o-transform: translate(@x, @y);
    -ms-transform: translate(@x, @y);
    transform: translate(@x, @y);
  }
  .opacity(@opacity: 0.5) {
    @percent: @opacity * 100;
    /* IE 8 */
    -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{percent})";
    /* IE 5-7 */
    filter: ~"alpha(opacity=@{percent})";
    /* Netscape */
    -moz-opacity: @opacity;
    /* Safari 1.x */
    -khtml-opacity: @opacity;
    /* Webkit */
    -webkit-opacity: @opacity;
    /* Good browsers */
    opacity: @opacity;
  }
}

#transition {
  .all(@duration:0.2s, @ease:ease-out) {
    -webkit-transition: all @duration @ease;
    -moz-transition: all @duration @ease;
    transition: all @duration @ease;
  }
  .property(@property: width,@delay: 200ms, @ease:ease-out){
    transition: @property @delay @ease;
    -moz-transition: @property @delay @ease;
    -webkit-transition: @property @delay @ease;
    -o-transition: @property @delay @ease;
  }
  .duration(@duration: 0.2s) {
    -moz-transition-duration: @duration;
    -webkit-transition-duration: @duration;
    transition-duration: @duration;
  }
}

#content {
  .columns(@colwidth: 250px, @colcount: 0, @colgap: 50px, @columnRuleColor: #EEE, @columnRuleStyle: solid, @columnRuleWidth: 1px) {
    -moz-column-width: @colwidth;
    -moz-column-count: @colcount;
    -moz-column-gap: @colgap;
    -moz-column-rule-color: @columnRuleColor;
    -moz-column-rule-style: @columnRuleStyle;
    -moz-column-rule-width: @columnRuleWidth;
    -webkit-column-width: @colwidth;
    -webkit-column-count: @colcount;
    -webkit-column-gap: @colgap;
    -webkit-column-rule-color: @columnRuleColor;
    -webkit-column-rule-style: @columnRuleStyle;
    -webkit-column-rule-width: @columnRuleWidth;
    column-width: @colwidth;
    column-count: @colcount;
    column-gap: @colgap;
    column-rule-color: @columnRuleColor;
    column-rule-style: @columnRuleStyle;
    column-rule-width: @columnRuleWidth;
  }
}

#box {
    .border-box() {
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
    }
    .content-box() {
        -moz-box-sizing: content-box;
        -ms-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        -o-box-sizing: content-box; 
        box-sizing: content-box;
    } 
    .padding-box() {
        -moz-box-sizing: padding-box;
        -ms-box-sizing: padding-box;
        -webkit-box-sizing: padding-box;
        -o-box-sizing: padding-box;
        box-sizing: padding-box;
    } 
    .margin-box() {
        -moz-box-sizing: margin-box;
        -ms-box-sizing: margin-box;
        -webkit-box-sizing: margin-box;
        -o-box-sizing: margin-box;
        box-sizing: margin-box;
    }
}
