/*-------------------------------------------------*/
/* =  content - home sections
/*-------------------------------------------------*/
#container {
  padding-top: 67px;
  overflow: hidden;
}
.slider1 {
  position: relative;
}
.slider1 .banner-thumbs {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100px;
  left: 0;
  bottom: 50px;
}
.slider1 .banner-thumbs ul.slider-thumbnails {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.slider1 .banner-thumbs ul.slider-thumbnails li {
  border-top: 2px solid #3a3d41;
  margin: 0;
  width: 25%;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  float: left;
}
.slider1 .banner-thumbs ul.slider-thumbnails li a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  background: #3a3d41;
  display: block;
  border: none !important;
  margin: 0;
  width: 100%;
  padding: 30px 10px;
  overflow: hidden;
}
.slider1 .banner-thumbs ul.slider-thumbnails li a span {
  display: inline-block;
  float: left;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background: @skin-color;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  font-family: arial;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.slider1 .banner-thumbs ul.slider-thumbnails li a h2 {
  color: #3a3d41;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  margin: 0 0 2px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
  text-transform: inherit;
  margin: 3px 0 0 60px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  text-align: left;
}
.slider1 .banner-thumbs ul.slider-thumbnails li a p {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-left: 60px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  text-align: left;
}
.slider1 .banner-thumbs ul.slider-thumbnails li a:hover {
  background: #3a3d41;
  opacity: 0.7;
}
.slider1 .banner-thumbs ul.slider-thumbnails li.active {
  border-top: 2px solid @skin-color;
}
.slider1 .banner-thumbs ul.slider-thumbnails li.active a span {
  background: #ffffff;
  color: #3a3d41;
}
.slider1 {
  overflow: hidden;
}
.slider1 .tp-bannertimer {
  display: none;
}
.slotholder:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(58, 61, 65, 0.8);
}

.tp-caption.large_bold_white {
  font-size: 60px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}
.tp-caption.medium_thin_white {
  color: #ffffff;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
}
.tp-caption.medium_thin_white .button-large {
  margin: 0 10px;
}
.tp-caption.large_bold_grey {
  font-size: 60px;
  color: #3a3d41;
  font-weight: 700;
  text-align: center;
}
.tp-caption.large_bold_grey span {
  color: @skin-color;
  display: block;
}
.tp-caption.medium_thin_grey {
  color: #999999;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.slider-fullwidth {
  margin-top: -67px;
}
.title-section {
  padding: 64px 56px;
  text-align: center;
  background: #f9fafb;
}
.title-section h1 {
  color: #3a3d41;
  font-size: 24px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  margin: 0 0 7px;
}
.title-section p {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
  color: #999999;
  font-size: 18px;
  margin: 0;
}
.services-section .services-box {
  background: url('../upload/banners/back.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px 0;
  position: relative;
}
.services-section .services-box .services-post {
  margin-bottom: 25px;
  text-align: center;
  position: relative;
  z-index: 2;
}
.services-section .services-box .services-post .services-head {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.services-section .services-box .services-post .services-head a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  width: 60px;
  height: 60px;
  background: #f9fafb;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin-bottom: 22px;
}
.services-section .services-box .services-post .services-head a i {
  color: #3a3d41;
  line-height: 60px;
  font-size: 30px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.services-section .services-box .services-post .services-head h2 {
  color: #3a3d41;
  font-size: 24px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  margin: 0 0 7px;
  color: #ffffff;
  margin-bottom: 3px;
}
.services-section .services-box .services-post .services-head span {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  margin: 0;
}
.services-section .services-box .services-post .services-head:after {
  position: absolute;
  content: '';
  width: 60px;
  height: 1px;
  background: #ffffff;
  bottom: 0;
  left: 50%;
  margin-left: -30px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.services-section .services-box .services-post p {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
}
.services-section .services-box .services-post:hover .services-head a {
  background: @skin-color;
}
.services-section .services-box .services-post:hover .services-head a i {
  color: #ffffff;
}
.services-section .services-box .services-post:hover .services-head:after {
  background: @skin-color;
}
.services-section .services-box:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: rgba(58, 61, 65, 0.8);
}

.portfolio-box .project-post {
  text-align: center;
}
.portfolio-box .project-post .project-gal {
  position: relative;
}
.portfolio-box .project-post .project-gal img {
  width: 100%;
}
.portfolio-box .project-post .project-gal .hover-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.65);
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  opacity: 0;
}
.portfolio-box .project-post .project-gal .hover-box a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  width: 40px;
  height: 40px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background: @skin-color;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.portfolio-box .project-post .project-gal .hover-box a i {
  font-size: 14px;
  color: #ffffff;
  line-height: 40px;
}
.portfolio-box .project-post .project-gal .hover-box a:hover {
  background: #3a3d41;
}
.portfolio-box .project-post .project-gal .hover-box a.zoom {
  right: 50%;
  margin-right: 35px;
}
.portfolio-box .project-post .project-gal .hover-box a.link {
  left: 50%;
  margin-left: 35px;
}
.portfolio-box .project-post .project-content {
  padding: 25px 10px;
  background: #f9fafb;
  border-bottom: 1px solid transparent;
}
.portfolio-box .project-post .project-content h2 {
  color: #3a3d41;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  margin: 0 0 2px;
  text-transform: uppercase;
}
.portfolio-box .project-post .project-content p {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
  margin-bottom: 0;
  font-size: 12px;
  color: #999999;
}
.portfolio-box .project-post:hover .project-gal .hover-box {
  visibility: visible;
  opacity: 1;
}
.portfolio-box .project-post:hover .project-gal .hover-box a {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.portfolio-box .project-post:hover .project-gal .hover-box a.zoom {
  margin-right: 5px;
}
.portfolio-box .project-post:hover .project-gal .hover-box a.link {
  margin-left: 5px;
}
.portfolio-box .project-post:hover .project-content {
  border-bottom: 1px solid @skin-color;
  background: #ffffff;
}
div.buttons {
  text-align: center;
  margin: 50px 0;
}
div.buttons a.button-third {
  margin: 0 8px;
}
a.button-third {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  padding: 10px 15px;
  color: #999999;
  font-size: 12px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 3px 0 #e5e5e5;
  -webkit-box-shadow: 0 3px 0 #e5e5e5;
  -moz-box-shadow: 0 3px 0 #e5e5e5;
  -o-box-shadow: 0 3px 0 #e5e5e5;
  margin: 0;
}
a.button-third i {
  font-size: 12px;
}
a.button-third:hover {
  border: 1px solid #999999;
}
.owl-pagination {
  display: none;
}
.owl-theme .owl-controls {
  display: none !important;
}
.features-section {
  background: url('../upload/banners/back2.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px 0;
  position: relative;
  overflow: hidden;
}
.features-section .container {
  position: relative;
  z-index: 3;
}
.features-section .container .features-head {
  margin-bottom: 75px;
  text-align: center;
}
.features-section .container .features-head h1 {
  color: #3a3d41;
  font-size: 24px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  margin: 0 0 7px;
  color: #ffffff;
  margin-bottom: 16px;
}
.features-section .container .features-head p {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
  color: #999999;
  font-size: 14px;
}
.features-section .container .feature-list {
  padding: 0;
  margin: 0 0 30px;
}
.features-section .container .feature-list li {
  list-style: none;
}
.features-section .container .feature-list li span {
  display: inline-block;
  float: left;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #ffffff;
  text-align: center;
}
.features-section .container .feature-list li span i {
  color: #ffffff;
  font-size: 18px;
  line-height: 40px;
}
.features-section .container .feature-list li .list-cont {
  margin-left: 54px;
  padding-top: 10px;
}
.features-section .container .feature-list li .list-cont h3 {
  color: #ffffff;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  margin: 0 0 16px;
}
.features-section .container .feature-list li .list-cont p {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
  color: #999999;
}
.features-section .container .image-place {
  text-align: center;
  position: relative;
}
.features-section .container .image-place img {
  max-width: 100%;
  margin-bottom: -60px;
}
.features-section:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: rgba(58, 61, 65, 0.8);
}
.features-section:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 60px;
  bottom: 0;
  left: 0;
  background: #f9fafb;
  z-index: 2;
}
.blog-section .title-section {
  background: #ffffff;
}
.blog-section .blog-post {
  margin: 0 10px;
}
.blog-section .blog-post img {
  width: 100%;
}
.blog-section .blog-post .post-content {
  overflow: hidden;
}
.blog-section .blog-post .post-content .post-date {
  float: left;
  width: 60px;
  height: 90px;
  background: #3a3d41;
  text-align: center;
  padding: 12px 2px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.blog-section .blog-post .post-content .post-date p {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
  margin: 0;
  color: #ffffff;
  font-size: 14px;
  line-height: 30px;
}
.blog-section .blog-post .post-content .post-date p span {
  display: block;
  position: relative;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
}
.blog-section .blog-post .post-content .post-date p span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 1px;
  background: #ffffff;
  bottom: 0px;
  left: 50%;
  margin-left: -10px;
}
.blog-section .blog-post .post-content .content-data {
  margin-left: 60px;
  padding: 15px 20px;
  background: #f9fafb;
  min-height: 90px;
}
.blog-section .blog-post .post-content .content-data h2 {
  color: #3a3d41;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  margin: 0 0 2px;
  text-transform: uppercase;
  line-height: 20px;
  margin: 0;
}
.blog-section .blog-post .post-content .content-data h2 a {
  color: #3a3d41;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.blog-section .blog-post .post-content .content-data h2 a:hover {
  color: @skin-color;
}
.blog-section .blog-post .post-content .content-data p {
  font-size: 13px;
  color: #dddddd;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 24px;
  color: #999999;
  font-size: 12px;
  margin: 0;
}
.blog-section .blog-post .post-content .content-data p a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  color: #999999;
}
.blog-section .blog-post:hover .post-date {
  background: @skin-color;
}
.client-section {
  background: #f9fafb;
  padding-bottom: 60px;
  overflow: hidden;
}
.client-section ul.client-list {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.client-section ul.client-list li {
  list-style: none;
  float: left;
  width: 16.666%;
}
.client-section ul.client-list li a {
  width: 100%;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.client-section ul.client-list li a img {
  width: 100%;
}
.client-section ul.client-list li a:hover {
  opacity: 0.7;
}
.client-section .bx-wrapper .bx-pager {
  display: none;
}
.client-section .bx-wrapper .bx-controls-direction a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  top: 0;
  width: 40px;
  height: 40px;
  color: #999999;
  font-size: 12px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 3px 0 #e5e5e5;
  -webkit-box-shadow: 0 3px 0 #e5e5e5;
  -moz-box-shadow: 0 3px 0 #e5e5e5;
  -o-box-shadow: 0 3px 0 #e5e5e5;
  margin: 0 8px;
  position: absolute;
  margin-top: 10px;
  background: #ffffff;
}
.client-section .bx-wrapper .bx-controls-direction a.bx-prev {
  margin-left: -40px;
}
.client-section .bx-wrapper .bx-controls-direction a.bx-next {
  margin-right: -40px;
}
.client-section .bx-wrapper .bx-controls-direction a.bx-prev:before {
  font-family: 'FontAwesome';
  content: "\f104";
  position: absolute;
  font-size: 12px;
  color: #999999;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  line-height: 40px;
  -webkit-backface-visibility: hidden;
}
.client-section .bx-wrapper .bx-controls-direction a.bx-next:before {
  font-family: 'FontAwesome';
  content: "\f105";
  position: absolute;
  font-size: 12px;
  color: #999999;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  line-height: 40px;
  -webkit-backface-visibility: hidden;
}
.client-section .bx-wrapper .bx-controls-direction a:hover {
  border: 1px solid #999999;
}